@import '../../../../scss/theme-bootstrap';

[data-component='search-gnav-input-field'] > .elc-spinner-wrapper {
  width: auto;
  height: 24px;
  background: $color-white;
}

.gnav-header-formatter__search {
  display: flex;
  flex-direction: column;
  .elc-search-icon-wrapper {
    height: 24px;
    &:focus {
      outline: $color-outline-default auto 5px;
    }
  }
  .elc-search-gnav-box {
    margin-top: 24px;
    padding: 48px 0 12px;
    .elc-input-search-field {
      font-weight: 700;
      @include text-body-text--large;
      &::placeholder {
        @include text-body-text--large;
      }
    }
    .elc-search-close-icon-wrapper {
      position: absolute;
      #{$rdirection}: 16px;
      top: 16px;
      @media #{$cr19-xlarge-up} {
        #{$rdirection}: 24px;
        top: 24px;
      }
      .elc-search-close-icon {
        height: 20px;
        width: 20px;
      }
    }
  }
  [data-component='search-gnav-input-field'] div.elc-icon-search {
    background: none;
    top: unset;
    #{$ldirection}: unset;
    transform: none;
  }
  .elc-search-active-screen-wrapper {
    .elc-search-content-grid-wrapper {
      margin-top: 28px;
      padding: 0;
      @media #{$cr19-xlarge-up} {
        gap: 0;
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
        max-width: 1920px;
        padding: 0 40px;
      }
      .elc-search-article {
        &__img-wrapper {
          margin: 0;
          padding-bottom: 24px;
        }
        &__info {
          align-items: flex-start;
          justify-content: flex-start;
          margin: 0;
          margin-bottom: 24px;
        }
        &__wrapper {
          border: 0;
          @media #{$cr19-xlarge-up} {
            padding: 0 12px;
          }
          .elc-search-article {
            &__img {
              height: 522px;
              max-height: 600px;
              width: 100%;
            }
            &__title {
              @include text-title--med;
              margin-bottom: 0;
              text-align: #{$ldirection};
            }
            &__short-desc {
              @include text-body-text;
            }
            &__link {
              @include text-link--style-1;
              font-family: $helvetica-regular-font-family;
              position: relative;
              text-decoration: none;
            }
          }
        }
      }
    }
    width: 100%;
    padding: 0 20px;
    z-index: 10003;
    .elc-tabs__list {
      padding: 0;
      .elc-tabs__tab {
        margin-top: 0;
        padding-top: 15px;
        &:hover,
        &--current {
          border-top: 3px solid $cr19-border-black;
          text-decoration: none;
        }
      }
    }
    @media #{$cr19-xlarge-up} {
      #{$ldirection}: auto;
      #{$rdirection}: -100%;
      transition: #{$rdirection} 0.7s ease-in-out;
      &.transition-active {
        #{$rdirection}: 0;
        top: 115px;
      }
    }
    .elc-grid-container {
      margin: 20px 0 0;
    }
    .elc-search-result-overlay-message {
      font-family: $helvetica-bold-font-family;
      font-size: 16px;
      @media #{$cr19-xlarge-up} {
        font-size: 20px;
      }
    }
    .elc-search-results-wrapper {
      @include shade-picker-style;
      padding: 0;
      @media #{$cr19-xlarge-up} {
        column-gap: unset;
      }
      .elc-product-brief {
        .elc-shade-swatches-wrapper {
          display: none;
        }
      }
      .elc-search-results-message-wrapper {
        .elc-search-result-overlay-message,
        .elc-search-result-adjustment-message,
        .elc-search-no-result-message {
          font-family: $helvetica-bold-font-family;
          font-size: 16px;
          @media #{$cr19-xlarge-up} {
            font-size: 20px;
          }
        }
      }
      .elc-search-suggestion-container {
        .elc-search-suggestion-wrapper {
          display: flex;
          padding: 15px 0 5px;
          @media #{$cr19-xlarge-up} {
            padding: 10px 0;
          }
          .elc-search-typeahead-title {
            width: 100%;
            @media #{$cr19-xlarge-up} {
              padding: 0;
            }
          }
          .elc-search-suggestion-value {
            border: none;
            .elc-search-suggestion-link {
              text-decoration: underline;
              &:focus {
                outline: $color-outline-default auto 5px;
              }
            }
          }
          .elc-search-suggestion-value span {
            font-family: $helvetica-light-font-family;
            font-size: 17px;
            line-height: 24px;
            text-align: center;
            padding: 5px;
          }
        }
      }
    }
  }
  .elc-search-enhanced-wrapper {
    padding: 15px 0 5px;
    @media #{$cr19-xlarge-up} {
      padding-top: 20px;
    }
    .elc-search-enhanced-grid-wrapper {
      width: 100%;
      .elc-product-images-wrapper {
        min-height: 220px;
      }
    }
    .elc-search-enhanced-grid-title,
    .elc-search-enhanced-trending-title {
      color: $color-black-88opacity;
      font-family: $helvetica-bold-font-family;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.17px;
      line-height: 20px;
      margin: 0;
      @media #{$cr19-xlarge-up} {
        font-size: 20px;
        letter-spacing: 0.21px;
        line-height: 24px;
      }
    }
    .elc-search-enhanced-trending-terms-wrapper {
      .elc-search-enhanced-trending-list {
        .elc-search-trending-term {
          &__link {
            font-family: $helvetica-light-font-family;
            font-size: 17px;
            font-weight: 300;
            line-height: 24px;
            padding: 0 5px;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .elc-search-active-screen-wrapper,
  .elc-search-enhanced-wrapper {
    .elc-search-results-wrapper,
    .elc-search-enhanced-grid-wrapper {
      .elc-product-quick-view-button-wrapper,
      .elc-product-quick-view-icon-wrapper {
        display: none;
      }
      .elc-rating-component {
        width: unset;
      }
    }
  }
  .elc-search-in-suggestion,
  .elc-search-term-suggestion,
  .elc-search-suggestion-icon-wrapper,
  .elc-icon-search-magnifier,
  .elc-icon-search-circle,
  .elc-product-size-wrapper {
    display: none;
  }
  .elc-search-results-button {
    background: url('#{$base-theme-path}img/icons/src/search-icon.svg') 0 center no-repeat;
    box-shadow: none;
    font-size: 0;
    height: 20px;
    #{$ldirection}: auto;
    margin: 0 30px;
    padding: 0;
    position: absolute;
    #{$rdirection}: 0;
    .search-overlay-displayed & {
      visibility: hidden;
      @media #{$medium-up} {
        min-width: 20px;
        visibility: visible;
      }
    }
    top: 75px;
    width: 20px;
    a:focus {
      outline: $color-outline-default auto 5px;
    }
    @media #{$cr19-xlarge-up} {
      @include elc-button;
      background: transparent;
      color: $color-black;
      font-size: 30px;
      height: 64px;
      margin: 0 18px;
      text-align: #{$rdirection};
      text-transform: capitalize;
      top: 53px;
      width: 220px;
    }
  }
}

.elc-voice-search-wrapper {
  #{$rdirection}: 16px;
  position: absolute;
  top: 56px;
  z-index: 1000;
  h2 {
    font-size: 24px;
    font-family: $helvetica-bold-font-family;
    letter-spacing: get-letter-spacing(10);
    line-height: get-line-height(24px, 32px);
    margin: 15px 0;
    position: absolute;
    top: 46%;
  }
  p {
    font-family: $helvetica-regular-font-family;
    font-size: 14px;
    letter-spacing: get-letter-spacing(0);
    line-height: get-line-height(10px, 16px);
    margin: 0;
    position: absolute;
    top: 54%;
    &.elc-voice-search-term {
      text-align: center;
      text-transform: capitalize;
    }
  }
  .elc-search-voice-transcoder {
    padding: 15.5px 15px;
  }
  .elc-search-microphone-wrapper {
    #{$ldirection}: 50%;
    transform: translate(-50%, -50%);
    top: 30%;
  }
  .elc-search-speak-wrapper {
    padding: 50px 16px;
  }
  .elc-searching-wrapper,
  .elc-error-capture-voice-wrapper,
  .elc-listen-message-wrapper,
  .elc-heading-allow-microphone {
    padding: 0 20px;
  }
  .elc-error-capture-voice-wrapper {
    p {
      position: absolute;
      top: 25%;
    }
  }
  .elc-listen-description {
    font-weight: 700;
    padding-bottom: 15px;
    position: absolute;
    top: 53%;
  }
  .elc-search-speak-icon-wrapper {
    &::after {
      content: '';
      display: block;
      height: 200px;
      position: absolute;
      width: 200px;
      top: 0;
    }
  }
  .elc-microphone-icon {
    background: url('/sites/clinique/themes/cl_base/img/icons/src/mic-fill.svg') center no-repeat;
    height: 20px;
    mask: 20px;
    -webkit-mask: 20px;
    width: 20px;
  }
  .elc-search-speak-microphone-icon {
    background: url('/media/export/images/global/voice_search_icon.gif') center no-repeat;
    background-size: 91% 67%;
    height: 305px;
    mask: none;
    width: 435px;
    -webkit-mask: none;
  }
  .elc-speak-now-message {
    display: none;
  }
  .elc-listen-example-search-term {
    position: absolute;
    top: 57%;
  }
}

[lang='fr'] {
  .elc-microphone-icon {
    display: none;
  }
}
